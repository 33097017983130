import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { ModalClose } from 'app/components/Common/Header/ModalClose'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { ChevronRight } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import disableScroll from 'disable-scroll'
import { rgba } from 'emotion-rgba'
import { Link } from 'gatsby'
import React, { memo, useEffect, useState } from 'react'

export interface Props {
  cta?: ButtonProps
  description?: string
  label?: string
  languageCode: string
  image?: ImageProps
  title?: string
  URL?: string
}

export const Sticker = memo(function Sticker({
  cta,
  description,
  label,
  languageCode,
  image,
  title,
  URL,
}: Props) {
  const [firstLoad, setFirstLoad] = useState(false)
  const [modalStickerStatus, setModalStickerStatus] = useState(false)
  const buttonDOM = (
    <LabelContent>
      {label}
      <ChevronRight />
    </LabelContent>
  )
  const EXTERNAL_URL_REGEX = new RegExp('^(https?|ftp|www)://')
  const externalURL = URL && EXTERNAL_URL_REGEX.test(URL)

  useEffect(() => {
    disableScroll.off()
  }, [])

  return (
    <Container>
      {label ? (
        URL ? (
          externalURL ? (
            <ExtLink href={URL} rel="noopener" target="_blank">
              {buttonDOM}
            </ExtLink>
          ) : (
            <Anchor to={URL}>{buttonDOM}</Anchor>
          )
        ) : (
          <Label
            onClick={() => {
              if (!modalStickerStatus) {
                setFirstLoad(true)
                setModalStickerStatus(true)
                if (window.innerWidth > 1023) {
                  disableScroll.on()
                } else {
                  document.body.style.overflow = 'hidden'
                }
              } else {
                if (window.innerWidth > 1023) {
                  disableScroll.off()
                } else {
                  document.body.style.overflow = 'auto'
                }
                setModalStickerStatus(false)
              }
            }}
          >
            {buttonDOM}
          </Label>
        )
      ) : null}

      {firstLoad ? (
        <Modal
          className={`${modalStickerStatus ? 'open' : ''}${
            firstLoad ? ' first' : ''
          }`}
          dial={5}
          onClick={() => {
            if (window.innerWidth > 1023) {
              disableScroll.off()
            } else {
              document.body.style.overflow = 'auto'
            }
            setModalStickerStatus(false)
          }}
        >
          <Wrapper row stretch onClick={(e) => e.stopPropagation()}>
            {image ? (
              <ImageWrap>
                <Image media="(min-width: 1920px)" {...image} />
              </ImageWrap>
            ) : null}
            <TextWrap className={!image ? 'full-width' : undefined}>
              <ModalClose
                languageCode={languageCode}
                onClick={() => {
                  if (window.innerWidth > 1023) {
                    disableScroll.off()
                  } else {
                    document.body.style.overflow = 'auto'
                  }
                  setModalStickerStatus(false)
                }}
                variant="compact"
              />
              {title ? <Title>{title}</Title> : null}
              {description ? (
                <Description
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              ) : null}
              {cta ? <CTA {...cta} /> : null}
            </TextWrap>
          </Wrapper>
        </Modal>
      ) : null}
    </Container>
  )
})

const Container = styled.aside`
  padding: 0 3.333vw;
`

const Style = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9rem;
  height: 9rem;
  border: 0.125rem solid ${rgba(theme.colors.variants.neutralLight4, 0.2)};
  border-radius: 50%;
  color: ${theme.colors.variants.neutralLight3};
  cursor: pointer;
  font-family: ${theme.fontFamily.paragraph};
  font-size: 0.875rem;
  font-weight: 700;
  letter-spacing: 0.0437rem;
  line-height: 1.125rem;
  padding: 0 1.375rem;
  position: absolute;
  top: 11.25rem;
  left: 6.667vw;
  z-index: 300;
  text-align: center;
  text-transform: uppercase;
  transition: 0.3s ease-out;
  &:hover {
    background: ${theme.colors.variants.neutralDark2};
    border-color: transparent;
    svg {
      opacity: 0.2;
    }
  }

  svg {
    width: 100%;
    height: auto;
    max-height: 1rem;
    fill: none;
    margin-top: 0.75rem;
    stroke: ${theme.colors.variants.neutralLight4};
    stroke-linecap: round;
    stroke-width: 2.5;
    transition: 0.3s ease-out;
  }

  @media (max-width: 1199px) {
    width: 100%;
    height: auto;
    justify-content: space-between;
    background: ${theme.colors.variants.neutralLight2};
    color: ${theme.colors.variants.primaryDark1};
    border: 0;
    border-radius: 0;
    padding: 1rem 1.375rem;
    top: 5rem;
    left: 0;

    > span {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    svg {
      width: auto;
      height: 1rem;
      stroke: ${theme.colors.variants.primaryDark1};
      margin-top: 0;
      transform: translateY(0.125rem);
    }
  }
`

const Label = styled.div`
  ${Style}
`

const Anchor = styled(Link)`
  ${Style}
`

const ExtLink = styled.a`
  ${Style}
`

const LabelContent = styled.span``

const Modal = styled(FlexBox)`
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  padding: 0 1.5rem;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transition: 0.15s ease-out;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.7;
    position: absolute;
    top: 0;
    left: 0;
  }
  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;

    > div {
      transform: translateY(0);
    }
  }
  &.first {
    animation: opacity 0.15s;
    > div {
      animation: fadeWrapper 0.6s;
    }
    img {
      animation: opacity 1s;
    }
  }

  @keyframes fadeWrapper {
    0% {
      transform: translateY(5.625rem);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes opacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @media (max-width: 767px) {
    padding: 0;
  }
`

const Wrapper = styled(FlexBox)`
  width: 100%;
  max-width: 73.5rem;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  cursor: default;
  margin: auto;
  transform: translateY(5.625rem);
  transition: 0.6s;

  @media (max-width: 767px) {
    display: block;
    height: 100vh;
    max-height: -webkit-fill-available;
    overflow-y: auto;
    transform: none;
    -webkit-overflow-scrolling: auto;
  }
`

const ImageWrap = styled.div`
  width: 50%;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(1.05);
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const TextWrap = styled.div`
  width: 50%;
  padding: 5.625rem 3.33vw 3.75rem;
  position: relative;
  &.full-width {
    width: 100%;
    text-align: center;
  }

  @media (max-width: 1023px) {
    width: 100%;
    padding: 5rem 1.875rem 3rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.625rem;
  font-weight: 400;
  line-height: 2.1875rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark5};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 2rem;
  margin-top: 1.375rem;

  @media (max-width: 1023px) {
    font-size: 1rem;
    line-height: 1.75rem;
  }
`

const CTA = styled(Button)`
  margin-top: 2.5rem;
`
